import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[3];

const Makepeace = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Jill D. Makepeace" profile_key="makepeace">
      <Meta site={siteMetadata} title="Jill D. Makepeace" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>With a singular focus on defending criminal matters, Jill Makepeace’s practice is divided between conducting trials and appeals. Jill has represented individuals charged with a broad spectrum of criminal offences, including alleged crimes of violence, all types of sexual offences and serious drug cases. A significant concentration of her trial practice is defending professionals alleged to have engaged in criminal and regulatory impropriety in the course of their vocation.</p>

				<p>Jill regularly appears before all levels of court in Ontario.  She also appears before administrative tribunals, including the College of Physicians and Surgeons of Ontario.</p>

				<p>Jill received her LL.B. from the University of Windsor in 2003 and her LL.M. from the University of Alberta in 2008. In 2003-2004, Jill secured a unique clerkship opportunity in Yellowknife devoted to the lengthy civil trial arising out of the murder of nine miners during a volatile labour dispute in 1992. After completing her articles with the firm, Jill was called to the Ontario bar in June of 2006.</p>

				<p>In addition to her commitment to practice, Jill was a devoted sessional lecturer at the University of Windsor Faculty of Law. For a decade, she taught criminal law courses, including an advanced seminar on criminal motions geared to students pursuing criminal law careers. In 2010, Jill was awarded the Students’ Law Society Special Lecturer Award for exemplary teaching and exceptional dedication to Windsor Law.</p>

				<p>Jill is a member of the Criminal Lawyers’ Association, Ontario Bar Association, and the Advocates’ Society.  She currently serves as co-editor of the publication of the Criminal Lawyers’ Association, For the Defence.</p>
			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default Makepeace
